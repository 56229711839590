<template>
    <v-card flat>
        <enquiry-dialog :enquiry="selectedEnquiry" @clearDialog="selectedEnquiry = null"/>
        <v-container>
            <nice-header>Enquiries</nice-header>
                <v-list three-line v-if="enquiries && enquiries.length > 0">
                    <template v-for="(message, i) in enquiries">
                        <v-list-item :key="message.id" @click="selectedEnquiry = message">
                            <v-list-item-avatar>
                                <v-icon>mdi-account-circle</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title v-text="message.product ? message.product.name : message.message"></v-list-item-title>
                                <v-list-item-subtitle>
                                    <span class="text--primary">
                                        {{ message.customer.name }}
                                    </span> &mdash; {{message.message}}
                                </v-list-item-subtitle>
                            </v-list-item-content>

                        </v-list-item>
                        <v-divider
                          :key="i"
                          inset
                        />

                    </template>
                </v-list>
                <div v-else class="py-5 pl-3">You have no customer enquiries</div>
        </v-container>
    </v-card>
</template>


<script>
    import {enquiries} from '@/graphql'
    export default {
        apollo: {
            enquiries
        },
        data: () => ({
            selectedEnquiry: null
        })
    }
</script>
